import * as Styled from "./TopControls.styles";

import { useCallback, useEffect, useState } from "react";

import { Switch } from "antd";
import { useLocation } from "context/LocationContext";

function TopControls() {
  const [isEnabled, setIsEnabled] = useState(true);

  const { currentLocation, locationActions, sendCompleteOnboardingCallRequest, lastOnboardingCallRequest, onboardingAuthUrlRequest } = useLocation();

  const [isModalCompleteOnboardingCallOpen, setIsModalCompleteOnboardingCallOpen] = useState(false);
  const [isLightspeedLocation, setIsLightspeedLocation] = useState(false);
  const [isSquareLocation, setIsSquareLocation] = useState(false);
  const [isOnboardingRequest24HoursOld, setIsOnboardingRequest24HoursOld] = useState(false);
  const [isOnboardingRequestCompleted, setIsOnboardingRequestCompleted] = useState(false);
  const [isModalOnboardingUrlOpen, setIsModalOnboardingUrlOpen] = useState(false);

  const isModalEnabled = !!lastOnboardingCallRequest;

  const is24HoursAgo = (date: Date) => {
    const hoursMillis = 1000 * 60 * 60 * 24;
    const hoursAgo = Date.now() - hoursMillis;

    return date.getTime() > hoursAgo;
  };
  
 

  useEffect(() => {
    if (currentLocation) {
      setIsEnabled(currentLocation?.enabled);
      setIsLightspeedLocation(currentLocation?.posType === "lightspeed");
      setIsSquareLocation(currentLocation?.posType === "square");
    }
  }, [currentLocation]);

  useEffect(() => {
    if (lastOnboardingCallRequest) {
      const lastOnboardingRequestCreatedAt = new Date(lastOnboardingCallRequest?.createdAt ?? "");
      const isRequest24HoursOld = is24HoursAgo(lastOnboardingRequestCreatedAt);
      setIsOnboardingRequest24HoursOld(isRequest24HoursOld);

      const lastOnboardingRequestArray = lastOnboardingCallRequest?.onboardingStatusRequests;
      const isOnboardRequestCompleted = !lastOnboardingRequestArray ||
      lastOnboardingRequestArray?.length === 0 ||
      lastOnboardingRequestArray[0].status === "COMPLETED_ONBOARDING_REQUEST";

      setIsOnboardingRequestCompleted(isOnboardRequestCompleted);
    }
  }, [lastOnboardingCallRequest]);


  const handleChangeEnabled = useCallback(() => {
    setIsEnabled(!isEnabled);

    locationActions.update({
      newEnabledState: !isEnabled,
    });
  }, [isEnabled, locationActions]);


  const handleCompleteOnboarding = useCallback(() => {
    sendCompleteOnboardingCallRequest({
      id: currentLocation?.id,
      onboardingCode: "DONE"
    });

    setIsModalCompleteOnboardingCallOpen(false);

  }, [sendCompleteOnboardingCallRequest]);

  const showCompleteOnboardingCallModal = () => {
    setIsModalCompleteOnboardingCallOpen(true);
  };

  const handleCancelOnboardingCallModal = () => {
    setIsModalCompleteOnboardingCallOpen(false);
  };

  const showOnboardingUrlModal = () => {
    setIsModalOnboardingUrlOpen(true);
  };

  const handleCancelOnboardingUrlModal = () => {
    setIsModalOnboardingUrlOpen(false);
  };

  return (
    <Styled.Container>

      {
        (isLightspeedLocation || isSquareLocation) && (
          <>
            <Styled.Button
              onClick={showOnboardingUrlModal}
              type="primary"
            >
              Onboarding Url
            </Styled.Button>

            <Styled.Modal
              title="Onboarding Auth Url"
              open={isModalOnboardingUrlOpen}
              onCancel={handleCancelOnboardingUrlModal}
              footer={[
                <Styled.Button key="Cancel" onClick={handleCancelOnboardingUrlModal}>
                  Cancel
                </Styled.Button>
              ]}
            >
              <Styled.Paragraph copyable>{onboardingAuthUrlRequest?.authUrl}</Styled.Paragraph>
            </Styled.Modal>
          </>
        )
      }
      {
        isLightspeedLocation && isModalEnabled && (
          <>
            <Styled.Button
              onClick={showCompleteOnboardingCallModal}
              type={isOnboardingRequest24HoursOld && !isOnboardingRequestCompleted ? "primary" : "link"}
            >
              {isOnboardingRequest24HoursOld && !isOnboardingRequestCompleted ? "Complete Onboarding" : isOnboardingRequestCompleted ? "Onboarding Complete" : "Onboarding Expired"}
            </Styled.Button>

            <Styled.Modal
              title="Complete Onboarding"
              open={isModalCompleteOnboardingCallOpen}
              onCancel={handleCancelOnboardingCallModal}
              footer={[
                <Styled.Button key="Cancel" onClick={handleCancelOnboardingCallModal}>
                  Cancel
                </Styled.Button>,
                <Styled.Button key="submit" type="primary" onClick={handleCompleteOnboarding} disabled={!isOnboardingRequest24HoursOld || isOnboardingRequestCompleted}>
                  Submit
                </Styled.Button>
              ]}
            >
              {JSON.stringify(lastOnboardingCallRequest, undefined, 4)}
             
            </Styled.Modal>
          
          </>
        )
      }

      <Styled.SwitchContainer>
        Enable Location
        <Switch
          key={currentLocation?.locationId}
          checked={isEnabled}
          onChange={handleChangeEnabled}
          title="Enable Location"
        />
      </Styled.SwitchContainer>
    </Styled.Container>
  );
}

export default TopControls;
