import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import getCheckMatch, {
  GetCheckMatchPropsResponse as CheckMatchDataProps,
} from "utilities/getCheckMatch";
import updateCheckMatch, {
  CheckMatchMenuGroupMapProps,
} from "utilities/updateCheckMatch";

import { addBreadcrumb } from "@sentry/react";
import isDifferent from "utilities/isDifferent";
import { queryKeys } from "utilities/constants";
import updateCheckMatchEnabled from "utilities/updateCheckMatchEnabled";
import useApi from "hooks/useApi";
import { useAuth } from "./AuthContext";
import { useLocation } from "./LocationContext";
import { useNotifications } from "./NotificationsContext";

export interface MappingStateProps {
  [key: string]: string;
}

export interface CheckMatchMenuGroupProps {
  menuGroupId: string;
  name: string;
  checkMatch: number;
  lastUpdate: string;
}


export interface CheckMatchInitDataProps {
  closeOfDayHour: number | null;
  timeZone: string | null;
}

interface CheckMatchContextProps {
  checkMatchData?: CheckMatchDataProps;
  handleUpdateCheckMatchEnabled: (checked: boolean) => void;
  isCheckMatchLoading: boolean;
  isCheckMatchEnabled: boolean;
  isCheckMatchError: boolean;
  isCheckMatchMappingsSaving: boolean;
  isCheckMatchSaving: boolean;
  checkMatchError: Error | unknown | null;
  cancelChanges: () => void;
  initialMappingState: CheckMatchMenuGroupMapProps | null | undefined;
  mappingState: CheckMatchMenuGroupMapProps | null | undefined;
  refetchCheckMatch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<CheckMatchDataProps | undefined, unknown>>;
  setIsCheckMatchEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setMappingState: React.Dispatch<
    React.SetStateAction<CheckMatchMenuGroupMapProps | null>
  >;
  updateCheckMatch: (newCheckMatchData: CheckMatchDataProps) => void;
  initCheckMatch: (checkMatchInitData: CheckMatchInitDataProps) => void;
  updateCheckMatchMappings: () => void;
  updateCheckMatchMappingsEnabled: (checked: boolean) => void;
  updateMappingState: (menuGroupId: string, value: string) => void;
}

export const CheckMatchContext = createContext<CheckMatchContextProps | null>(
  null
);

CheckMatchContext.displayName = "CheckMatchContext";

export function CheckMatchProvider(props: any): React.ReactElement {
  const [checkMatchData, setCheckMatchData] =
    useState<CheckMatchContextProps["checkMatchData"]>();
  const [initialMappingState, setInitialMappingState] =
    useState<CheckMatchMenuGroupMapProps | null>({});
  const [mappingState, setMappingState] =
    useState<CheckMatchMenuGroupMapProps | null>({});
  const [isCheckMatchEnabled, setIsCheckMatchEnabled] =
    useState<boolean>(false);
  const [isCheckMatchMappingsSaving, setIsCheckMatchMappingsSaving] =
    useState<boolean>(false);
  const [isCheckMatchSaving, setIsCheckMatchSaving] = useState<boolean>(false);

  const [isFetchError, setIsFetchError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { notify } = useNotifications();

  const { token } = useAuth();

  const { currentLocation, partnerId, locationDetails } = useLocation();

  const apiClient = useApi();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (locationDetails) {
      setIsCheckMatchEnabled(locationDetails.checkMatchEnabled);
    }
  }, [locationDetails]);

  const {
    isFetching: isCheckMatchLoading,
    isError: isCheckMatchError,
    error: checkMatchError,
    data,
    refetch: refetchCheckMatch,
  } = useQuery<CheckMatchDataProps | undefined>(
    queryKeys.checkMatch,
    async () =>
      await getCheckMatch({
        id: currentLocation?.id,
        locationId: currentLocation?.locationId,
        partnerId,
        posType: currentLocation?.posType,
        token,
        apiClient,
      }),
    {
      enabled: 
        !!currentLocation?.id &&
        !!currentLocation?.locationId &&
        !!partnerId &&
        !!currentLocation?.posType,
      onError: (error: any) => {
        if (error?.message) {
          setErrorMessage(`Error on Check Match: ${error.message}`);
          setIsFetchError(true);
        }
      },
    }
  );

  // reset query data and mapping state on location change
  useEffect(() => {
    if (currentLocation?.id) {
      queryClient.setQueryData(queryKeys.checkMatch, undefined);
      setInitialMappingState({});
      setMappingState({});
      setCheckMatchData(undefined);

      void refetchCheckMatch();
    }
  }, [currentLocation?.id, queryClient, refetchCheckMatch]);

  useEffect(() => {
    if (data) {
      setCheckMatchData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isFetchError && errorMessage) {
      const notification = () => {
        notify({
          type: "error",
          message: errorMessage,
        });
      };

      void notification();

      setIsFetchError(false);
    }
  }, [errorMessage, isFetchError, notify]);

  // initial mapping state
  useEffect(() => {
    if (data?.checkMatchMenuGroupMap) {
      const initialState = Object.keys(
        data.checkMatchMenuGroupMap
      ).reduce<CheckMatchMenuGroupMapProps>((acc, key) => {
        const item = data.checkMatchMenuGroupMap[key];
        const { menuGroupId, value } = item;

        if (value) {
          acc[menuGroupId] = {
            menuGroupId,
            value,
          };
        }

        return acc;
      }, {});

      setInitialMappingState(initialState);
      setMappingState(initialState);
    }
  }, [data?.checkMatchMenuGroupMap]);

  const updateCheckMatchEnabledMutation = useMutation(
    async (checkMatchEnabled: boolean) => {
      if (!locationDetails) {
        console.error(
          "updateCheckMatchEnabledMutation: Location Details are missing."
        );

        return;
      }

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update checkMatchEnabled for locationId:${currentLocation?.id}`,
      });

      const response = await updateCheckMatchEnabled({
        checkMatchEnabled,
        locationDetails,
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: () => {
        // reset checkMatch data before the query gets disabled by the locationDetails.checkMatchEnabled
        queryClient.setQueryData(queryKeys.checkMatch, undefined);

        queryClient.setQueryData(queryKeys.locationDetails, {
          ...locationDetails,
          checkMatchEnabled: !isCheckMatchEnabled,
        });

        notify({
          type: "success",
          message: "Location Config updated successfully!",
        });
      },
      onError: (error: any) => {
        notify({
          type: "error",
          message: `Error enabling Check Match: ${error?.message}`,
        });

        console.error("There was an error enabling CheckMatch:", error);
      },
    }
  );

  const handleUpdateCheckMatchEnabled = useCallback(
    (checked: boolean) => {
      setIsCheckMatchEnabled(checked);
      updateCheckMatchEnabledMutation.mutate(checked);
    },
    [updateCheckMatchEnabledMutation]
  );

  const updateCheckMatchMappingsEnabledMutation = useMutation(
    async (checked: boolean) => {
      if (!checkMatchData || !locationDetails || !mappingState) {
        return;
      }

      // remove all "no maps"
      const newMapping = Object.entries(
        mappingState
      ).reduce<CheckMatchMenuGroupMapProps>((newState, [key, { value }]) => {
        if (value !== "no map") {
          newState[key] = {
            menuGroupId: key,
            value,
          }; // Keep the entry if the value is not 'no map'
        }
        // Otherwise, it's omitted from the newState
        return newState;
      }, {});

      setIsCheckMatchMappingsSaving(true);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update checkMatchMappings for locationId:${currentLocation?.id}`,
      });

      const response = await updateCheckMatch({
        checkMatchData: {
          ...checkMatchData,
          enabled: checked,
          partnerVenueId: locationDetails.partnerVenueId,
          partnerVenueName: locationDetails.name,
          locationId: locationDetails.locationId,
          posType: locationDetails.posType,
          closeOfDayHour: checkMatchData.closeOfDayHour ?? 4,
          timeZone: checkMatchData.timeZone ?? "America/New_York",
        },
        newMapping,
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: async () => {
        notify({ type: "success", message: "Data saved successfully!" });
        setIsCheckMatchMappingsSaving(false);

        await refetchCheckMatch();
      },
      onError: (error: any) => {
        setIsCheckMatchMappingsSaving(false);

        notify({
          type: "error",
          message: `Error saving CheckMatch: ${error?.message}`,
        });

        console.error("There was an error saving the data: ", error);
      },
    }
  );

  const handleUpdateCheckMatchMappingsEnabled = useCallback(
    (checked: boolean) => {
      setIsCheckMatchEnabled(checked);
      updateCheckMatchMappingsEnabledMutation.mutate(checked);
    },
    [updateCheckMatchMappingsEnabledMutation]
  );

  const updateMappingState = useCallback(
    (menuGroupId: string, value: string) => {
      setMappingState((prevState) => {
        if (!prevState || !menuGroupId) return prevState;

        return {
          ...prevState,
          [menuGroupId]: { menuGroupId, value },
        };
      });
    },
    []
  );

  const cancelChanges = useCallback(() => {
    setMappingState(initialMappingState);
  }, [initialMappingState]);

  const updateCheckMatchMappingsMutation = useMutation(
    async (newMapping: CheckMatchMenuGroupMapProps) => {
      if (!checkMatchData || !locationDetails) {
        return;
      }

      setIsCheckMatchMappingsSaving(true);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update checkMatchMappings for locationId:${currentLocation?.id}`,
      });

      const response = await updateCheckMatch({
        checkMatchData: {
          ...checkMatchData,
          enabled: locationDetails.checkMatchEnabled,
          partnerVenueId: locationDetails.partnerVenueId,
          partnerVenueName: locationDetails.name,
          locationId: locationDetails.locationId,
          posType: locationDetails.posType,
          closeOfDayHour: checkMatchData.closeOfDayHour ?? 4,
          timeZone: checkMatchData.timeZone ?? "America/New_York",
        },
        newMapping,
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: async () => {
        notify({ type: "success", message: "Data saved successfully!" });
        setIsCheckMatchMappingsSaving(false);

        await refetchCheckMatch();
      },
      onError: (error: any) => {
        setIsCheckMatchMappingsSaving(false);

        notify({
          type: "error",
          message: `Error saving CheckMatch: ${error?.message}`,
        });

        console.error("There was an error saving the data: ", error);
      },
    }
  );

  const handleUpdateCheckMatchMappings = useCallback(() => {
    if (!mappingState) {
      return;
    }

    // remove all "no maps"
    const cleanedUpMap = Object.entries(
      mappingState
    ).reduce<CheckMatchMenuGroupMapProps>((newState, [key, { value }]) => {
      if (value !== "no map") {
        newState[key] = {
          menuGroupId: key,
          value,
        }; // Keep the entry if the value is not 'no map'
      }
      // Otherwise, it's omitted from the newState
      return newState;
    }, {});

    const isMapDifferent =
      initialMappingState &&
      cleanedUpMap &&
      isDifferent(initialMappingState, cleanedUpMap);

    if (isMapDifferent) {
      updateCheckMatchMappingsMutation.mutate(cleanedUpMap);
    } else {
      cancelChanges();
      // send a notification?
    }
  }, [
    cancelChanges,
    initialMappingState,
    mappingState,
    updateCheckMatchMappingsMutation,
  ]);

  const updateCheckMatchMutation = useMutation(
    async (newCheckMatchData: CheckMatchDataProps) => {
      if (!checkMatchData || !locationDetails) {
        return;
      }

      setIsCheckMatchSaving(true);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update Check Match for locationId:${currentLocation?.id}`,
      });

      const response = await updateCheckMatch({
        checkMatchData: {
          ...newCheckMatchData,
          enabled: locationDetails.checkMatchEnabled,
          partnerVenueId: locationDetails.partnerVenueId,
          partnerVenueName: locationDetails.name,
          locationId: locationDetails.locationId,
          posType: locationDetails.posType,
        },
        newMapping: checkMatchData.checkMatchMenuGroupMap,
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: async () => {
        notify({ type: "success", message: "Data saved successfully!" });
        setIsCheckMatchSaving(false);

        await refetchCheckMatch();
      },
      onError: (error: any) => {
        setIsCheckMatchSaving(false);

        notify({
          type: "error",
          message: `Error saving CheckMatch: ${error?.message}`,
        });

        console.error("There was an error saving the data: ", error);
      },
    }
  );

  const initCheckMatchMutation = useMutation(
    async (checkMatchInitProps: CheckMatchInitDataProps) => {
      if (!locationDetails) {
        return;
      }

      setIsCheckMatchSaving(true);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update Check Match for locationId:${currentLocation?.id}`,
      });

      const response = await updateCheckMatch({
        checkMatchData: {
          closeOfDayHour: checkMatchInitProps.closeOfDayHour,
          timeZone: checkMatchInitProps.timeZone,
          checkMatchMenuGroupMap: {},
          enabled: locationDetails.checkMatchEnabled,
          partnerVenueId: locationDetails.partnerVenueId,
          partnerVenueName: locationDetails.name,
          locationId: locationDetails.locationId,
          posType: locationDetails.posType,
        },
        newMapping: {},
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: async () => {
        notify({ type: "success", message: "Data saved successfully!" });
        setIsCheckMatchSaving(false);

        await refetchCheckMatch();
      },
      onError: (error: any) => {
        setIsCheckMatchSaving(false);

        notify({
          type: "error",
          message: `Error saving CheckMatch: ${error?.message}`,
        });

        console.error("There was an error saving the data: ", error);
      },
    }
  );

  const handleUpdateCheckMatch = useCallback(
    (newCheckMatchData: CheckMatchDataProps) => {
      updateCheckMatchMutation.mutate(newCheckMatchData);
    },
    [updateCheckMatchMutation]
  );

  const handleInitCheckMatch = useCallback(
    (newCheckMatchInitData: CheckMatchInitDataProps) => {
      initCheckMatchMutation.mutate(newCheckMatchInitData);
    },
    [updateCheckMatchMutation]
  );

  const value: CheckMatchContextProps = {
    cancelChanges,
    checkMatchData,
    checkMatchError,
    handleUpdateCheckMatchEnabled,
    initialMappingState,
    isCheckMatchEnabled,
    isCheckMatchError,
    isCheckMatchLoading,
    isCheckMatchMappingsSaving,
    isCheckMatchSaving,
    mappingState,
    refetchCheckMatch,
    setIsCheckMatchEnabled,
    setMappingState,
    updateCheckMatch: handleUpdateCheckMatch,
    initCheckMatch: handleInitCheckMatch,
    updateCheckMatchMappings: handleUpdateCheckMatchMappings,
    updateCheckMatchMappingsEnabled: handleUpdateCheckMatchMappingsEnabled,
    updateMappingState,
  };

  return (
    <CheckMatchContext.Provider value={value} {...props} />
  ) as React.ReactElement;
}

export function useCheckMatch(): CheckMatchContextProps {
  const context = useContext(CheckMatchContext);

  if (context === undefined) {
    throw new Error("useCheckMatch must be used within a CheckMatchProvider");
  }

  // Because we are getting the context from CheckMatchProvider here, if context is
  // not 'undefined' (ie: the CheckMatchProvider is not a parent of this component),
  // we can be sure context is not 'null' here
  if (context === null) {
    throw new Error("CheckMatchProvider supplied null context");
  }

  return context;
}
