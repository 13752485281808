import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { addBreadcrumb, setTag } from "@sentry/react";
import completeOnboardingCall, { Response, SuccessResponse } from "utilities/completeOnboardingCall";
import getCurrentLocationConfig, {
  LocationConfigsResponse,
} from "utilities/getCurrentLocationConfig";
import getLastCompleteOnboardingRequest, {
  GetLastOnboardingCallResponse,
} from "utilities/getLastCompleteOnboardingRequest";
import getLocationDetails, {
  LocationDetailsProps,
} from "utilities/getLocationDetails";
import getLocations, { Location } from "utilities/getLocations";
import getOnboardingAuthUrlRequest, {
  GetOnboardingAuthUrlResponse,
} from "utilities/getOnboardingAuthUrl";
import updateLocationConfig, {
  UpdateLocationConfigProps as NewConfigProps,
} from "utilities/updateLocationConfig";

import createLocation from "utilities/createLocation";
import { queryKeys } from "utilities/constants";
import updateLocation from "utilities/updateLocation";
import useApi from "hooks/useApi";
import { useAuth } from "./AuthContext";
import { useNotifications } from "./NotificationsContext";
import { useParams } from "react-router-dom";

interface UpdateLocationConfigsProps extends NewConfigProps {
  partnerId?: string;
}

export interface LocationContextProps {
  currentLocation?: Location;
  locations?: Location[] | null;
  initialLocations: Location[] | null | undefined;
  isLocationsError: boolean;
  locationsError: Error | null;
  isLocationsLoading: boolean;
  partnerId?: string;
  partnerKey?: string;
  locationActions: {
    create: ({
      locationId,
      name,
      posType,
      partnerKey,
      upserveUsername,
      upservePassword,
    }: CreateLocationProps) => void;
    isCreateLoading: boolean;
    update: ({
      newName,
      newEnabledState,
      newPartnerVenueId,
    }: UpdateLocationProps) => void;
    updateConfigs: ({
      id,
      locationId,
      name,
      partnerId,
      posType,
      configs,
    }: UpdateLocationConfigsProps) => void;
    isUpdateLoading: boolean;
    updateSuccess: boolean;
  };
  setLocations: React.Dispatch<React.SetStateAction<Location[] | undefined>>;
  locationDetails?: LocationDetailsProps | null;
  isLocationDetailsError: boolean;
  isLocationDetailsLoading: boolean;
  locationDetailsError: Error | null;
  refetchLocationDetails?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<LocationDetailsProps | null, Error>>;
  configs: LocationConfigsResponse | undefined;
  isLoadingConfigs: boolean;
  refetchLocationConfigs: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<LocationConfigsResponse | undefined, Error>>;
  sendCompleteOnboardingCallRequest: ({
    id,
    onboardingCode
  }: CompleteOnboardingCallProps) => void;
  lastOnboardingCallRequest: GetLastOnboardingCallResponse | undefined;
  onboardingAuthUrlRequest: GetOnboardingAuthUrlResponse | undefined;
}

interface CreateLocationProps {
  locationId: string;
  name: string;
  partnerId: string;
  partnerKey: string;
  posType: string;
  upservePassword?: string;
  upserveUsername?: string;
}

interface UpdateLocationProps {
  newName?: string;
  newEnabledState?: boolean;
  newPartnerVenueId?: string;
}

interface CompleteOnboardingCallProps {
  id?: number;
  onboardingCode: string;
}

export const LocationContext = createContext<LocationContextProps | null>(null);

LocationContext.displayName = "LocationContext";

export function LocationProvider(props: any): ReactElement {
  const [locations, setLocations] = useState<Location[] | undefined>(undefined);
  const [currentLocation, setCurrentLocation] = useState<Location | undefined>(
    undefined
  );
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);

  const { token } = useAuth();

  const { partnerId, posGUID } = useParams();

  const { notify } = useNotifications();

  const queryClient = useQueryClient();

  const apiClient = useApi();

  // Get Locations
  const {
    isFetching: isLoading,
    isError: isLocationsError,
    error: locationsError,
    data: initialLocations,
    refetch: refetchLocations,
  } = useQuery<Location[] | undefined, Error>(
    queryKeys.locations,
    async () => await getLocations({ token, partnerId, apiClient }),
    {
      enabled: !!partnerId,
    }
  );

  // Get Location Details
  const getCurrentLocationDetails = useCallback(async () => {
    return await getLocationDetails({
      id: currentLocation?.id,
      partnerId,
      posType: currentLocation?.posType,
      token,
      apiClient,
    });
  }, [
    apiClient,
    currentLocation?.id,
    currentLocation?.posType,
    partnerId,
    token,
  ]);

  const {
    data: locationDetails,
    isFetching: isLocationDetailsLoading,
    isError: isLocationDetailsError,
    error: locationDetailsError,
    refetch: refetchLocationDetails,
  } = useQuery<LocationDetailsProps | null, Error>(
    queryKeys.locationDetails,
    getCurrentLocationDetails,
    {
      enabled: !!posGUID,
      refetchOnWindowFocus: false,
    }
  );

  const {
    isFetching: isLoadingConfigs,
    data: configs,
    refetch: refetchLocationConfigs,
  } = useQuery<LocationConfigsResponse | undefined, Error>(
    queryKeys.locationConfigs,
    async () =>
      await getCurrentLocationConfig({
        id: currentLocation?.id,
        partnerId,
        posType: currentLocation?.posType,
        token,
        apiClient,
      }),
    {
      enabled: !!posGUID,
    }
  );

  const {
    data: lastOnboardingCallRequest,
    refetch: refetchLastOnboardingCallRequest,
  } = useQuery<GetLastOnboardingCallResponse | undefined, Error>(
    queryKeys.lastOnboardingRequest,
    async () =>
      await getLastCompleteOnboardingRequest({
        locationId: currentLocation?.locationId,
        partnerId,
        token,
        apiClient
      }),
    {
      enabled: currentLocation?.posType === "lightspeed",
    }
  );

  const {
    data: onboardingAuthUrlRequest,
    refetch: refetchOnboardingAuthUrl,
  } = useQuery<GetOnboardingAuthUrlResponse | undefined, Error>(
    queryKeys.onboardingAuthUrl,
    async () =>
      await getOnboardingAuthUrlRequest({
        locationId: currentLocation?.locationId,
        partnerId,
        token,
        apiClient,
        posType : currentLocation?.posType,
      }),
    {
      enabled: (currentLocation?.posType === "lightspeed" || currentLocation?.posType === "square" ),
    }
  );

  useEffect(() => {
    if (currentLocation?.id) {
      queryClient.setQueryData(queryKeys.locationDetails, undefined);
      void refetchLocationDetails();

      queryClient.setQueryData(queryKeys.locationConfigs, undefined);
      void refetchLocationConfigs();

      queryClient.setQueryData(queryKeys.lastOnboardingRequest, undefined);
      void refetchLastOnboardingCallRequest();

      queryClient.setQueryData(queryKeys.onboardingAuthUrl, undefined);
      void refetchOnboardingAuthUrl();
    }
  }, [
    currentLocation?.id,
    queryClient,
    refetchLocationConfigs,
    refetchLocationDetails,
    refetchLastOnboardingCallRequest,
    refetchOnboardingAuthUrl,
  ]);

  useEffect(() => {
    if (initialLocations && !locations) {
      setLocations(initialLocations);
    }
  }, [initialLocations, locations]);

  useEffect(() => {
    if (partnerId && !posGUID) {
      queryClient.setQueryData(queryKeys.locations, undefined);
      setLocations(undefined);
      void refetchLocations();
    }
  }, [partnerId, posGUID, queryClient, refetchLocations]);

  useEffect(() => {
    if (locations) {
      const partnerLocation = locations.filter(
        (location) => location.locationId === posGUID
      )[0];

      setCurrentLocation(partnerLocation);
      setTag("locationId", posGUID);
    }
  }, [locations, posGUID]);

  useEffect(() => {
    if (isLoading) {
      setIsLocationsLoading(true);
    } else {
      setIsLocationsLoading(false);
    }
  }, [isLoading]);

  const createLocationMutation = useMutation(
    async ({
      locationId: newLocationId,
      name: newLocationName,
      partnerKey: partnerKeyForNewLocation,
      partnerId: partnerIdForNewLocation,
      posType: posTypeForNewLocation,
      upserveUsername: upserveUsernameForNewLocation,
      upservePassword: upservePasswordForNewLocation,
    }: {
      locationId: string;
      name: string;
      posType: string;
      partnerKey: string;
      partnerId: string;
      upserveUsername?: string;
      upservePassword?: string;
    }) => {
      setIsLocationsLoading(true);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Create new Location for partnerId:${partnerId}`,
      });

      const response = await createLocation(
        {
          checkMatchEnabled: false,
          enabled: true,
          locationId: newLocationId,
          logWebhook: false,
          name: newLocationName,
          partnerKey: partnerKeyForNewLocation,
          posType: posTypeForNewLocation,
          skipSend: false,
          tableStatusEnabled: false,
          upserveUsername: upserveUsernameForNewLocation,
          upservePassword: upservePasswordForNewLocation,
        },
        apiClient,
        partnerIdForNewLocation,
        token
      );

      return response;
    },
    {
      onSuccess: async () => {
        await refetchLocations();
        notify({
          type: "success",
          message: "Location created successfully!",
        });
      },
      onError: (error) => {
        notify({
          type: "error",
          message:
            "There was an error creating the location. Please try again.",
        });
        console.error("There was an error creating the location: ", error);
      },
    }
  );

  const handleCreateLocation = useCallback(
    ({
      locationId: newLocationId,
      name: newLocationName,
      posType: posTypeForNewLocation,
      partnerId: partnerIdForNewLocation,
      partnerKey: partnerKeyForNewLocation,
      upserveUsername: upserveUsernameForNewLocation,
      upservePassword: upservePasswordForNewLocation,
    }: CreateLocationProps) => {
      createLocationMutation.mutate({
        locationId: newLocationId,
        name: newLocationName,
        posType: posTypeForNewLocation,
        partnerId: partnerIdForNewLocation,
        partnerKey: partnerKeyForNewLocation,
        upserveUsername: upserveUsernameForNewLocation,
        upservePassword: upservePasswordForNewLocation,
      });
    },
    [createLocationMutation]
  );

  const updateLocationMutation = useMutation(
    async ({
      newName,
      newEnabledState,
      newPartnerVenueId,
    }: UpdateLocationProps) => {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update Location:${currentLocation?.id}`,
      });

      const response = await updateLocation(
        {
          id: currentLocation?.id,
          locationId: currentLocation?.locationId,
          name: newName ?? currentLocation?.name,
          enabled: newEnabledState ?? currentLocation?.enabled,
          partnerVenueId: newPartnerVenueId ?? currentLocation?.partnerVenueId,
          partnerId,
          posType: currentLocation?.posType ?? "",
        },
        apiClient,
        token
      );

      return response;
    },
    {
      onSuccess: () => {
        setCurrentLocation((prevState) => {
          if (!prevState) {
            return prevState;
          }

          return {
            ...prevState,
            enabled: !prevState?.enabled,
          };
        });

        notify({
          type: "success",
          message: "Location updated successfully!",
        });

        setTimeout(() => {
          updateLocationMutation.reset();
        }, 1000);
      },
      onError: (error) => {
        notify({
          type: "error",
          message:
            "There was an error updating the location. Please try again.",
        });
        console.error("There was an error updating the location: ", error);
        updateLocationMutation.reset();
      },
    }
  );

  const handleUpdateLocation = useCallback(
    ({ newName, newEnabledState, newPartnerVenueId }: UpdateLocationProps) => {
      updateLocationMutation.mutate({
        newName,
        newEnabledState,
        newPartnerVenueId,
      });
    },
    [updateLocationMutation]
  );

  const updateLocationConfigsMutation = useMutation(
    async ({
      id,
      locationId,
      name,
      partnerId,
      posType,
      configs,
    }: UpdateLocationConfigsProps) => {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update Location Configuration for locationId:${currentLocation?.id}`,
      });
      const response = await updateLocationConfig(
        {
          id,
          locationId,
          name,
          posType,
          configs,
        },
        apiClient,
        partnerId,
        token
      );

      return response;
    },
    {
      onSuccess: async () => {
        await refetchLocations();
        notify({
          type: "success",
          message: "Location Config updated successfully!",
        });
      },
      onError: (error) => {
        notify({
          type: "error",
          message:
            "There was an error updating the location. Please try again.",
        });
        console.error("There was an error updating the location: ", error);
      },
    }
  );

  const handleUpdateLocationConfigs = useCallback(
    ({
      id,
      locationId,
      name,
      partnerId,
      posType,
      configs,
    }: UpdateLocationConfigsProps) => {
      updateLocationConfigsMutation.mutate({
        id,
        locationId,
        name,
        partnerId,
        posType,
        configs,
      });
    },
    [updateLocationConfigsMutation]
  );


  const sendCompleteOnboardingCallRequestMutation = useMutation(
    async ({
      id,
      onboardingCode
    }: CompleteOnboardingCallProps) => {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Send complete onboarding call request for location :${currentLocation?.id}`,
      });

      const response = await completeOnboardingCall(
        {
          onboardingCode,
          id,
          partnerId,
          token,
          apiClient
        }
      );

      return response;
    },
    {
      onSuccess: async (body: Response) => {
        await refetchLocations();
        const successResponse = body as SuccessResponse;
    
        if (!successResponse.hasErrors) {
          notify({
            type: "success",
            message: "Success complete onboarding call!",
          });
        }
      },
      onError: (error) => {
        notify({
          type: "error",
          message:
            "There was an error completing onboarding call. Please try again.",
        });
        console.error("There was an error completing onboarding call: ", error);
      },
    }
  );

  const handleSendCompleteOnboardingCallRequest = useCallback(
    ({
      id,
      onboardingCode
    }: CompleteOnboardingCallProps) => {
      sendCompleteOnboardingCallRequestMutation.mutate({
        id,
        onboardingCode
      });
    }, 
    [sendCompleteOnboardingCallRequestMutation]
  );

  const value: LocationContextProps = {
    currentLocation,
    locations,
    initialLocations,
    isLocationsError,
    locationsError,
    isLocationsLoading,
    partnerId,
    locationActions: {
      create: handleCreateLocation,
      isCreateLoading: createLocationMutation.isLoading,
      update: handleUpdateLocation,
      updateConfigs: handleUpdateLocationConfigs,
      isUpdateLoading: updateLocationMutation.isLoading,
      updateSuccess: updateLocationMutation.isSuccess,
    },
    setLocations,
    locationDetails,
    isLocationDetailsLoading,
    isLocationDetailsError,
    locationDetailsError,
    refetchLocationDetails,
    configs,
    isLoadingConfigs,
    refetchLocationConfigs,
    sendCompleteOnboardingCallRequest: handleSendCompleteOnboardingCallRequest,
    lastOnboardingCallRequest,
    onboardingAuthUrlRequest,
  };
  return (
    <LocationContext.Provider value={value} {...props} />
  ) as ReactElement;
}

export function useLocation(): LocationContextProps {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error("useLocation must be used within a LocationProvider");
  }

  // Because we are getting the context from LocationProvider here, if context is
  // not 'undefined' (ie: the LocationProvider is not a parent of this component),
  // we can be sure context is not 'null' here
  if (context === null) {
    throw new Error("LocationProvider supplied null context");
  }

  return context;
}
