/* eslint-disable indent */
import {
  BadRequestError,
  InternalServerDramaError,
  InvalidDataError,
  NetworkError,
  NotFoundError,
  TooEarlyError,
} from "utilities/api/errors";
import { NotificationProps, useNotifications } from "context/NotificationsContext";

import { addBreadcrumb } from "@sentry/react";
import { environment } from "utilities/constants";
import sentryUniqueId from "utilities/sentryUniqueId";
import tryOrUndefined from "utilities/tryOrUndefined";
import { useAuth } from "context/AuthContext";

export interface ApiClientProps {
  get: ({ path, queryParams, headers, noRetry }: Request) => Promise<any>;
  post: ({
    path,
    queryParams,
    headers,
    body,
    noRetry,
  }: Request) => Promise<any>;
  put: ({ path, queryParams, headers, body, noRetry }: Request) => Promise<any>;
}

function useApi(): ApiClientProps {
  const { signOut } = useAuth();
  const { notify } = useNotifications();

  const apiClient = {
    get: async ({ path, queryParams, headers, noRetry }: Request) =>
      await request(
        new URL(path, environment.baseUrl),
        queryParams,
        {
          method: "GET",
          headers: prepareHeaders(headers),
          credentials: "include",
          mode: "cors",
        },
        noRetry,
        signOut,
        notify
      ),

    post: async ({ path, queryParams, headers, body, noRetry }: Request) =>
      await request(
        new URL(path, environment.baseUrl),
        queryParams,
        {
          method: "POST",
          headers: prepareHeaders(headers),
          body,
          credentials: "include",
          mode: "cors",
        },
        noRetry,
        signOut,
        notify
      ),

    put: async ({ path, queryParams, headers, body, noRetry }: Request) =>
      await request(
        new URL(path, environment.baseUrl),
        queryParams,
        {
          method: "PUT",
          headers: prepareHeaders(headers),
          body,
          credentials: "include",
          mode: "cors",
        },
        noRetry,
        signOut,
        notify
      ),
  };

  return apiClient;
}

export default useApi;

type QueryParamsInit = string[][] | Record<string, any> | string;

async function request(
  url: URL,
  queryParams: QueryParamsInit | undefined,
  config: RequestInit | undefined,
  noRetry?: boolean,
  signOut?: (() => void) | undefined,
  notify?: (notification: NotificationProps) => void
): Promise<any> {
  setQueryParams(queryParams, url);

  const request = {
    url: url?.toString(),
    queryParams,
    ...config,
  };

  let logData: any = await mapToJson(request, undefined);
  let error: Error | unknown | undefined;
  let errorMessage = `${request.method} ${url.pathname}${url.search}  > Failed!`;

  try {
    const response = await fetchRetry(
      url,
      config,
      defaultRetryOptions,
      noRetry
    );
    logData = await mapToJson(request, response);
    addFetchBreadCrumb(logData);

    if (response.ok && response.status === 204) {
      return await Promise.resolve();
    } else if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.hasErrors && notify) {
        await notify({
          type: "warning",
          message: jsonResponse.message,
        });
      }
      return jsonResponse;
    } else if (!response.ok && response.status === 401) {
      signOut?.();
    } else {
      errorMessage = `${request.method} ${url.pathname}${url.search} > Status ${response.status} - ${logData?.response?.statusText}`;
      error = await mapResponseError(response, logData);
    }
  } catch (err) {
    error = err;
    errorMessage = `${errorMessage} > ${error}`;
  }

  console.error(
    errorMessage,
    logData,
    error instanceof NetworkError ? undefined : error
  );

  if (error instanceof SyntaxError) {
    error = new InvalidDataError(error.message);
  }

  return await Promise.reject(error);
}

const setQueryParams = (queryParams: QueryParamsInit | undefined, url: URL) => {
  if (queryParams) {
    const params = new URLSearchParams(queryParams);
    params.forEach((value: string, key: string, _: URLSearchParams) =>
      url.searchParams.append(key, value)
    );
  }
};

const mapToJson = async (
  request: object,
  response: Response | undefined
): Promise<unknown> => {
  return {
    currentPath: window.location.pathname,
    request,
    response: response ? await mapResponseToJson(response) : {},
  };
};

const mapResponseToJson = async (response: Response): Promise<unknown> => {
  const responseBody = await bodyFromResponse(response);
  const responseStatusText = responseBody?.message
    ? responseBody?.message
    : response.statusText;

  return {
    body: responseBody,
    headers: response.headers.entries(),
    ok: response.ok,
    redirected: response.redirected,
    status: response.status,
    statusText: responseStatusText,
    type: response.type,
    url: response.url,
  };
};

const bodyFromResponse = async (
  response: Response
): Promise<any | undefined> => {
  if (response.status === 401) {
    localStorage.removeItem("authToken");
  }

  const responseBodyJson =
    response.status !== 204
      ? await tryOrUndefined(async () => await response.clone().json())
      : undefined;
  const responseBodyString =
    response.status !== 204
      ? await tryOrUndefined(async () => await response.clone().text())
      : undefined;
  return responseBodyJson ?? responseBodyString;
};

export interface Request {
  path: string;
  queryParams?: QueryParamsInit;
  headers?: HeadersInit;
  body?: BodyInit;
  noRetry?: boolean;
}

const prepareHeaders = (headers: HeadersInit | undefined): HeadersInit => {
  const sentryTransactionHeaders = {
    "X-Sentry-Unique-ID": sentryUniqueId.uniqueId(),
    "X-Sentry-Transaction-ID": sentryUniqueId.transactionId(),
    "X-Sentry-Transaction-Origin": sentryUniqueId.transactionEntryPoint(),
  };

  return { ...headers, ...sentryTransactionHeaders };
};

async function fetchRetry(
  input: RequestInfo | URL,
  init?: RequestInit,
  retry: Retry = defaultRetryOptions,
  noRetry?: boolean
): Promise<Response> {
  try {
    const response = await fetch(input, init);
    return response;
  } catch (error) {
    const { currentAttempt, maxAttempts } = retry;
    if (currentAttempt >= maxAttempts) throw error;
    if (noRetry) throw error;

    const nextRetryOptions = { ...retry, currentAttempt: currentAttempt + 1 };
    const nextRetryInMillis = retryDelayInMillis({
      ...retry,
      currentAttempt: currentAttempt + 1,
    });

    return await delay(nextRetryInMillis).then(
      async () => await fetchRetry(input, init, nextRetryOptions)
    );
  }
}

const delay = async (millis: number) =>
  await new Promise((resolve) => setTimeout(resolve, millis));

const retryDelayInMillis = ({
  currentAttempt,
  initialDelayInMillis,
  maxDelayInMillis,
  backOffFactor,
}: Retry) =>
  Math.min(
    initialDelayInMillis * backOffFactor ** currentAttempt,
    maxDelayInMillis
  );

interface Retry {
  currentAttempt: number;
  maxAttempts: number;
  initialDelayInMillis: number;
  maxDelayInMillis: number;
  backOffFactor: number;
}

const defaultRetryOptions = {
  currentAttempt: 1,
  maxAttempts: 2,
  initialDelayInMillis: 10000,
  maxDelayInMillis: 25000,
  backOffFactor: 2,
};

const mapResponseError = async (response: Response, logData: any) => {
  let error: Error;
  const responseBody = await bodyFromResponse(response);

  switch (response.status) {
    case 400:
      error = new BadRequestError(responseBody, logData);
      break;
    case 404:
      error = new NotFoundError(responseBody, logData);
      break;
    case 425:
      error = new TooEarlyError(responseBody, logData);
      break;
    case 500:
      error = new InternalServerDramaError(responseBody, logData);
      break;
    default:
      error = new NetworkError(
        response.status,
        response.statusText,
        responseBody,
        logData
      );
  }

  return error;
};

const addFetchBreadCrumb = (logData: any) => {
  addBreadcrumb({
    level: "info",
    category: "fetch_request",
    message: `Request: ${logData.request.url}`,
    data: { log: logData },
  });
};
