import { ReactElement, createContext, useCallback, useContext } from "react";
import { message, notification } from "antd";

import type { NotificationInstance } from "antd/es/notification/interface";

type NotificationType = "success" | "error" | "info" | "warning";

export interface NotificationProps {
  type: NotificationType;
  message: string;
}

interface NotificationContextProps {
  notify: (notification: NotificationProps) => void;
}

export const NotificationContext =
  createContext<NotificationContextProps | null>(null);

NotificationContext.displayName = "NotificationContext";

export function NotificationProvider(props: any): ReactElement {
  const [api, contextHolder] = notification.useNotification();
  const placement = "bottomRight";
  
  const notify = useCallback(
    ({ type, message: content }: NotificationProps) => {

      switch (type) {
      case "success":
        api.success({
          message: content,
          placement,
        });
        break;
      
      case "warning":
        api.warning({
          message: content,
          placement,
        });
        break;
      
      case "error":
        api.error({
          message: content,
          placement,
        });
        break;
      
      default:
        api.info({
          message: content,
          placement,
        });
        break;
      }
    },
    [api]
  );

  const value: NotificationContextProps = { notify };

  return (
    <NotificationContext.Provider value={value} {...props}>
      <>
        {contextHolder}
        {props.children}
      </>
    </NotificationContext.Provider>
  ) as ReactElement;
}

export function useNotifications(): NotificationContextProps {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }

  // Because we are getting the context from TableStatusProvider here, if context is
  // not 'undefined' (ie: the TableStatusProvider is not a parent of this component),
  // we can be sure context is not 'null' here
  if (context === null) {
    throw new Error("NotificationProvider supplied null context");
  }

  return context;
}
